<template lang="pug">
.relative
  button(@click="toggleDropdownMenu")
    icon-menu.size-6.text-smGray
  fade-transition
    .dropdown-menu.absolute.z-30.top-0.right-0.mt-7(
      v-if="dropdownMenuVisible"
      v-on-click-outside="onDropdownMenuClickOutside"
    )
      button.dropdown-menu__link(
        v-if="userStore.data.infl"
        @click="openModalCreatePromoCode(); closeDropdownMenu();"
      )
        icon-ticket.size-6
        span.ms-2 {{ $t('promo_code.create_promo_code') }}
      button.dropdown-menu__link(
        v-else
        @click="openModalEnterPromoCode(); closeDropdownMenu();"
      )
        icon-ticket.size-6
        span.ms-2 {{ $t('promo_code.promo_code') }}
      router-link.dropdown-menu__link(:to="appRoutes.INVITE" @click="closeDropdownMenu")
        icon-person-plus.size-6
        span.ms-2 {{ $t('nav.invite') }}

  fade-transition
    modal-create-promo-code(
      v-if="modalCreatePromoCodeVisible"
      @close="closeModalCreatePromoCode"
    )
  fade-transition
    modal-enter-promo-code(
      v-if="modalEnterPromoCodeVisible"
      @close="closeModalEnterPromoCode"
    )
</template>

<script lang="ts" setup>
// external
import { FadeTransition } from '@noction/vue-bezier';
import { vOnClickOutside } from '@vueuse/components';
// internal
import ModalCreatePromoCode from '@/components/profile/ModalCreatePromoCode.vue';
import ModalEnterPromoCode from '@/components/add-meme/ModalEnterPromoCode.vue';
import IconMenu from '@/assets/icons/menu.svg';
import IconTicket from '@/assets/icons/ticket.svg';
import IconPersonPlus from '@/assets/icons/person-plus.svg';
import useUserStore from '@/stores/user';
import useModal from '@/composables/useModal';
import useDropdown from '@/composables/useDropdown';
import appRoutes from '@/router/appRoutes';

const userStore = useUserStore();

const {
  dropdownMenuVisible,
  closeDropdownMenu,
  toggleDropdownMenu,
  onDropdownMenuClickOutside,
} = useDropdown();

const {
  modalVisible: modalEnterPromoCodeVisible,
  openModal: openModalEnterPromoCode,
  closeModal: closeModalEnterPromoCode,
} = useModal();
const {
  modalVisible: modalCreatePromoCodeVisible,
  openModal: openModalCreatePromoCode,
  closeModal: closeModalCreatePromoCode,
} = useModal();
</script>

<style lang="scss" scoped>
</style>
