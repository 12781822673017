// external
import type { AxiosInstance, AxiosResponse } from 'axios';
// internal
import type {
  RefreshTokenRequest,
  RefreshTokenResponse,
  PseudoAuthRequest,
  PseudoAuthResponse,
  UserData,
} from '@/types';

export default (api: AxiosInstance) => ({
  pseudoAuth(data: PseudoAuthRequest): Promise<AxiosResponse<PseudoAuthResponse>> {
    return api.post<PseudoAuthResponse>('/v1/auth/login/', data);
  },
  refreshToken(data: RefreshTokenRequest): Promise<AxiosResponse<RefreshTokenResponse>> {
    return api.post<RefreshTokenResponse>('/v1/auth/refresh-token/', data);
  },

  getUserData(): Promise<AxiosResponse<UserData>> {
    return api.get<UserData>('/v1/auth/me/');
  },
  setOnboardingDone(): Promise<AxiosResponse> {
    return api.get('/v1/users/unboarding-finish/');
  },

  saveTonWallet(data: any): Promise<AxiosResponse> {
    return api.post('/v1/users/add-ton/', data);
  },
});
