// external
import type { AxiosInstance, AxiosResponse } from 'axios';
// internal
import type {
  StickerPack,
  CreateStickerPackRequest,
  EditStickerPackRequest,
} from '@/types';

export default (api: AxiosInstance) => ({
  getStickerPacks(): Promise<AxiosResponse<StickerPack[]>> {
    return api.get<StickerPack[]>('/v1/stickers/');
  },

  addStickerPack(data: CreateStickerPackRequest): Promise<AxiosResponse<StickerPack>> {
    return api.post<StickerPack>('/v1/stickers/', data);
  },
  updateStickerPack(name: string, data: EditStickerPackRequest): Promise<AxiosResponse> {
    return api.patch(`/v1/stickers/${name}/`, data);
  },
});
