<template lang="pug">
mini-modal(
  :show="true"
  icon-close
  @close="$emit('close')"
)
  .text-center.font-bold.text-lg(class="mt-0.5") {{ $t('promo_code.promo_code') }}
  fade-transition(mode="out-in")
    .mt-4(v-if="!promoCodeApplied")
      label.block
        span.block {{ $t('promo_code.enter_code') }}
        input.input.w-full(
          v-model="promoCode"
          :class="{'is-error': promoCodeFormError}"
        )
        slide-y-down-transition
          span.block.text-sm.text-smError(v-if="promoCodeFormError")
            | {{ $t('promo_code.promo_code_must_have_at_least_6_characters') }}
      .grid.grid-cols-2.gap-4.mt-4.w-full
        button.btn(
          :disabled="!promoCode"
          @click="applyPromoCode"
        )
          icon-loading.absolute.size-6.animate-spin(v-if="promoCodeChecking")
          span(:class="{'text-transparent': promoCodeChecking}") {{ $t('common.apply') }}
        button.btn.is-white(@click="$emit('close')") {{ $t('common.close') }}
    .mt-4.text-center(v-else)
      .text-lg {{ $t('promo_code.you_got_items', { items: $t('promo_code.generations_count', promoGenerations) }) }}
      .mt-2.text-lg(v-if="promoDescription") {{ promoDescription }}
      .mt-4
        button.btn.is-white.w-full(@click="$emit('close')") {{ $t('common.close') }}
</template>

<script lang="ts" setup>
// external
import { ref, computed } from 'vue';
import { FadeTransition, SlideYDownTransition } from '@noction/vue-bezier';
// internal
import MiniModal from '@/components/ui/MiniModal.vue';
import IconLoading from '@/assets/icons/progress-circle.svg';
import useApi from '@/composables/useApi';
import useUserStore from '@/stores/user';

// Component {
interface ModalEnterPromoCodeEmits {
  (e: 'close'): void,
}
defineEmits<ModalEnterPromoCodeEmits>();
// }

const { apiPayment, apiErrorCatcher } = useApi();
const userStore = useUserStore();

const promoCode = ref<string>('');
const sendTried = ref<boolean>(false);
const promoCodeChecking = ref<boolean>(false);
const promoCodeApplied = ref<boolean>(false);
const promoGenerations = ref<number>(0);
const promoDescription = ref<string>('');

const promoCodeFormError = computed<boolean>(() => (
  sendTried.value && promoCode.value.length < 6
));

async function applyPromoCode() {
  sendTried.value = true;

  if (promoCode.value.length < 6) {
    return;
  }

  promoCodeChecking.value = true;

  try {
    const { data } = await apiPayment.applyPromoCode(promoCode.value);

    promoGenerations.value = data.generationLimit;
    promoDescription.value = data.promoDescription;
    promoCodeApplied.value = true;

    userStore.addCredits(data.generationLimit);
  } catch (e) {
    apiErrorCatcher(e);
  } finally {
    promoCodeChecking.value = false;
  }
}
</script>

<style lang="scss" scoped>
</style>
