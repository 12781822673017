// external
import Notify from 'simple-notify';
import { useI18n } from 'vue-i18n';
import type { IArgs } from 'simple-notify/dist/types';

interface AdditionalOptions {
  noTranslate?: boolean,
}
interface AllOptions extends Partial<IArgs>, AdditionalOptions {}

export type UseNotifications = (options?: AllOptions) => void;

/**
 * Return a function to show a notification.
 * By default type of notification is 'error'.
 */
export default function useNotifications(): UseNotifications {
  const { t } = useI18n();
  const defaultOptions: Partial<IArgs> = {
    status: 'error',
    text: t('errors.common'),
    customClass: 'font-semibold', // added to tailwind safelist
    distance: 50,
    autoclose: true,
    autotimeout: 4000,
    showIcon: false,
    type: 3,
    position: 'top x-center',
  };

  const notify = (options: AllOptions = {}) => {
    if (!options.noTranslate) {
      if (options.title) {
        options.title = t(options.title);
      }
      if (options.text) {
        options.text = t(options.text);
      }
    }

    new Notify({
      ...defaultOptions,
      ...options,
    });
  };

  return notify;
}
