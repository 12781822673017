<template lang="pug">
.avatar-border.relative.size-12.me-3.rounded-full(
  :class=`{
    'is-active is-for-infl': userStore.data.infl,
    'is-active is-for-premium': userStore.data.boostEnds !== null,
    'size-12': size === 'default',
    'size-32': size === 'big'
  }`
)
  .avatar-border__inner.w-full.h-full.bg-smGrayLight.rounded-full
    //- ******************** -//
    //- *** Main content *** -//
    //- ******************** -//
    slot
      router-link.shrink-0.w-full.h-full.flex-centered.bg-smGreen.rounded-full(:to="appRoutes.PROFILE")
        //- img(v-if="userStore.data.photoUrl" :src="userStore.data.photoUrl" alt="")
        //- v-else-if="userStore.data.username"
        span.uppercase.font-semibold.text-2xl.text-white
          | {{ userStore.data.username ? userStore.data.username[0] : '' }}
    //- ********************* -//
    //- *** Icon for Infl *** -//
    //- ********************* -//
    .avatar-border__icon.is-infl.absolute.flex-centered.border.border-smGrayLight.rounded-full(
      v-if="userStore.data.infl"
      :class=`{
        'size-6': size === 'default',
        'size-12': size === 'big',
      }`
    )
      icon-check-star.text-white(
        :class=`{
          'size-4': size === 'default',
          'size-8': size === 'big',
        }`
      )
    //- ************************ -//
    //- *** Icon for Premium *** -//
    //- ************************ -//
    .avatar-border__icon.is-premium.absolute.flex-centered.border.border-smGrayLight.rounded-full(
      v-else-if="userStore.data.boostEnds !== null"
      :class=`{
        'size-6': size === 'default',
        'size-12': size === 'big',
      }`
      @click.prevent.stop="toggleDropdownMenu"
    )
      icon-tg-star.text-white(
        :class=`{
          'size-4': size === 'default',
          'size-8': size === 'big',
        }`
      )
      fade-transition
        .absolute.z-30.top-4.left-4.min-w-64.py-2.px-3.bg-smGrayLight2.rounded-lg.shadow-lg(
          v-if="showPremiumTime && dropdownMenuVisible && userStore.data.boostEnds"
          v-html="$t('profile.your_boost_active_until', { date: formatDateTime(userStore.data.boostEnds) })"
          v-on-click-outside="onDropdownMenuClickOutside"
        )
</template>

<script lang="ts" setup>
// external
import { vOnClickOutside } from '@vueuse/components';
import { FadeTransition } from '@noction/vue-bezier';
// internal
import IconCheckStar from '@/assets/icons/check-star.svg';
import IconTgStar from '@/assets/icons/tg-star.svg';
import useUserStore from '@/stores/user';
import useFormat from '@/composables/useFormat';
import useDropdown from '@/composables/useDropdown';
import appRoutes from '@/router/appRoutes';

// Component {
interface ProfileAvatarProps {
  size?: 'default' | 'big',
  showPremiumTime?: boolean,
}
withDefaults(
  defineProps<ProfileAvatarProps>(),
  {
    size: 'default',
    showPremiumTime: true,
  },
);
// }

const userStore = useUserStore();
const { formatDateTime } = useFormat();
const {
  dropdownMenuVisible,
  toggleDropdownMenu,
  onDropdownMenuClickOutside,
} = useDropdown();
</script>

<style lang="scss" scoped>
.avatar-border {
  $ab: &;

  &.is-active {
    &.size-12 {
      padding: 2px;

      #{$ab}__inner {
        padding: 2px;
      }
    }
    &.size-32 {
      padding: 5px;

      #{$ab}__inner {
        padding: 3px;
      }
    }

    &.is-for-infl {
      background-image: linear-gradient(120deg, #89f7fe, #66a6ff);
    }
    &.is-for-premium {
      background: #fac515;
    }
  }

  &__icon {
    @apply -bottom-1 -right-1;

    &.is-infl {
      background-image: linear-gradient(120deg, #89f7fe, #66a6ff);
    }
    &.is-premium {
      background: #fac515;
    }
  }
}
</style>
