// external
import { createRouter, createWebHistory } from 'vue-router';
// internal
import routesScheme from '@/router/routesScheme';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routesScheme,
});

export default router;
