<template lang="pug">
mini-modal(
  :show="true"
  icon-close
  @close="$emit('close')"
)
  .text-center.font-bold.text-lg(class="mt-0.5") {{ $t('promo_code.create_promo_code') }}
  fade-transition(mode="out-in")
    .mt-4(v-if="!promoCodeId")
      label.block
        span {{ $t('promo_code.enter_description') }}
        input.input.w-full(v-model="description")
      .grid.grid-cols-2.gap-4.mt-4.w-full
        button.btn(@click="createPromoCode")
          icon-loading.absolute.size-6.animate-spin(v-if="creating")
          span(:class="{'text-transparent': creating}") {{ $t('common.apply') }}
        button.btn.is-white(@click="$emit('close')") {{ $t('common.close') }}
    .mt-4.text-center(v-else)
      .text-lg {{ $t('promo_code.promo_code_created') }}
      .mt-4.flex.items-center.justify-center
        span.py-1.px-2.bg-smGrayLight2.rounded-lg.text-lg {{ promoCodeId }}
        button.relative(class="active:top-px" @click="copyCode")
          icon-copy.ms-4.size-6
      .mt-6
        button.btn.is-white.w-full(@click="$emit('close')") {{ $t('common.close') }}
</template>

<script lang="ts" setup>
// external
import { ref } from 'vue';
import { FadeTransition } from '@noction/vue-bezier';
import copy from 'clipboard-copy';
// internal
import MiniModal from '@/components/ui/MiniModal.vue';
import IconLoading from '@/assets/icons/progress-circle.svg';
import IconCopy from '@/assets/icons/copy.svg';
import useApi from '@/composables/useApi';
import useNotifications from '@/composables/useNotifications';
import type { CreatePromoCodeRequest } from '@/types';

const { apiPayment, apiErrorCatcher } = useApi();
const notify = useNotifications();

const description = ref<string>('');
const creating = ref<boolean>(false);
const promoCodeId = ref<string>('');

async function createPromoCode() {
  try {
    creating.value = true;

    const query: CreatePromoCodeRequest = {};
    if (description.value) {
      query.description = description.value;
    }
    const { data } = await apiPayment.createPromoCode(query);

    if (!data.id) {
      throw new Error('errors.wrong_response_from_server');
    }

    promoCodeId.value = data.id;
  } catch (e) {
    apiErrorCatcher(e);
  } finally {
    creating.value = false;
  }
}
function copyCode() {
  copy(promoCodeId.value);
  notify({
    status: 'success',
    text: 'common.copied',
  });
}
</script>

<style lang="scss" scoped>
</style>
