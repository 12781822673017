<template lang="pug">
.nav.w-full.bg-white.rounded-t-2xl(
  :class="{'pb-8': blockPaddingBottom}"
)
  .relative.z-30.flex.items-end.justify-around.py-2
    router-link.nav-link(:to="appRoutes.AIRDROP" active-class="!text-smBlue")
      icon-radio-waves.nav-link__icon
      .nav-link__hint {{ $t('nav.airdrop') }}
    router-link.nav-link(:to="appRoutes.RATING" active-class="!text-smBlue")
      icon-stars-stacked.nav-link__icon
      .nav-link__hint {{ $t('nav.rating') }}
    router-link.nav-link.is-big(
      :to="appRoutes.SWIPES"
      active-class="!text-smBlue"
    )
      .nav-link__icon-wrapper
        icon-feed.nav-link__icon
      .nav-link__hint {{ $t('swipes.feed') }}
    router-link.nav-link(:to="appRoutes.INVITE" active-class="!text-smBlue")
      icon-person-plus.nav-link__icon
      .nav-link__hint {{ $t('nav.invite') }}

    router-link.nav-link(:to="appRoutes.QUESTS" active-class="!text-smBlue")
      icon-doc-check.nav-link__icon
      .nav-link__hint {{ $t('nav.quests') }}
</template>

<script lang="ts" setup>
// external
import { ref } from 'vue';
import TgWebApp from '@twa-dev/sdk';
// internal
import IconRadioWaves from '@/assets/icons/radio-waves.svg';
import IconStarsStacked from '@/assets/icons/stars-stacked.svg';
import IconPersonPlus from '@/assets/icons/person-plus.svg';
import IconDocCheck from '@/assets/icons/doc-check.svg';
import IconFeed from '@/assets/icons/feed.svg';
import appRoutes from '@/router/appRoutes';

const blockPaddingBottom = ref<boolean>(TgWebApp.platform === 'ios');
</script>

<style lang="scss" scoped>
.nav-link {
  @apply text-smGray;

  --big-link-size: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc((100% - var(--big-link-size)) / 4);

  &__icon {
    height: 24px;
  }
  &__icon-wrapper {
    @apply
      absolute
      flex items-center justify-center
      bg-smBlue border-4 border-white rounded-full
      text-white;

    top: -24px;
    width: var(--big-link-size);
    height: var(--big-link-size);
  }
  &__hint {
    @apply mt-2;

    font-size: 10px;
  }
}
</style>
