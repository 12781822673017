import { ref } from 'vue';
import { onKeyDown } from '@vueuse/core';
import type { Ref } from 'vue';

export interface UseModal {
  modalVisible: Ref<boolean>,
  openModal: () => void,
  closeModal: () => void,
  toggleModal: () => void,
}

export default function useModal(): UseModal {
  const modalVisible = ref<boolean>(false);

  let stopKeyDownListener: Function | null = null;

  function openModal() {
    modalVisible.value = true;
    document.body.style.overflow = 'hidden';

    stopKeyDownListener = onKeyDown(['Escape', 'Esc'], closeModal);
  }
  function closeModal() {
    modalVisible.value = false;
    document.body.style.overflow = 'visible';

    if (stopKeyDownListener) {
      stopKeyDownListener();
    }
  }
  function toggleModal() {
    modalVisible.value
      ? closeModal()
      : openModal();
  }

  return {
    modalVisible,
    openModal,
    closeModal,
    toggleModal,
  };
}
