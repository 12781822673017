// external
import type { AxiosInstance, AxiosResponse } from 'axios';
// internal
import type {
  GetRatingsRequest,
  GetRatingsResponse,
} from '@/types';

export default (api: AxiosInstance) => ({
  getRatings(params: GetRatingsRequest): Promise<AxiosResponse<GetRatingsResponse>> {
    return api.get<GetRatingsResponse>('/v1/users/rate/', { params });
  },
});
