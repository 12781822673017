export enum appRoutes {
  ROOT = '/',
  ADD_MEME = '/add-meme',
  RATING = '/rating',
  INVITE = '/invite',
  QUESTS = '/quests',
  AIRDROP = '/airdrop',
  PROFILE = '/profile',
  ONBOARDING = '/onboarding',
  SHARED_MEME = '/i/:shareId',
  STICKER_PACKS = '/stickers',
  SWIPES = '/swipes',
  NOT_SUPPORTED_PLATFORM = '/not-supported-platform',
}

/**
 * Replace params in template with values from params object
 */
export function buildPath(template: string, params: Record<string, string | number>): string {
  return template.replace(/\/(:(\w+))/g, (_, __, key: string) => `/${params[key].toString()}`);
}

export default appRoutes;
