// external
import type { AxiosInstance, AxiosResponse } from 'axios';
// internal
import type {
  Plan,
  GetPlansRequest,
  GetPaymentLinkResponse,
  PaymentSuccessResponse,
  CreatePromoCodeRequest,
  CreatePromoCodeResponse,
  ApplyPromoCodeResponse,
  PremuimPlan,
} from '@/types';

export default (api: AxiosInstance) => ({
  getPlans(params: GetPlansRequest = { tariffType: 'basic_generation' }): Promise<AxiosResponse<Plan[]>> {
    return api.get<Plan[]>('/v1/tariff/', { params });
  },
  getPaymentLink(planId: string | number): Promise<AxiosResponse<GetPaymentLinkResponse>> {
    return api.get<GetPaymentLinkResponse>(`/v1/tariff/${planId}/`);
  },

  getPaymentResult(): Promise<AxiosResponse<PaymentSuccessResponse>> {
    return api.get<PaymentSuccessResponse>(`/v1/payment/success/`);
  },

  createPromoCode(data: CreatePromoCodeRequest): Promise<AxiosResponse<CreatePromoCodeResponse>> {
    return api.post<CreatePromoCodeResponse>(`/v1/promocodes/`, data);
  },
  applyPromoCode(promoCodeId: string): Promise<AxiosResponse<ApplyPromoCodeResponse>> {
    return api.post<ApplyPromoCodeResponse>(`/v1/promocodes/${promoCodeId}/`);
  },

  getBoostPlans(): Promise<AxiosResponse<PremuimPlan[]>> {
    return api.get<PremuimPlan[]>('/v1/boost/');
  },
  getBoostPayLink(boostId: number): Promise<AxiosResponse<GetPaymentLinkResponse>> {
    return api.get<GetPaymentLinkResponse>(`/v1/boost/${boostId}/`);
  },
});
