// external
import { useI18n } from 'vue-i18n';

export interface UseFormat {
  formatNumber: (num: number) => string,
  formatDateTime: (date: Date | string | number) => string,
}

export default function useFormat() {
  const { locale } = useI18n();

  const formatNumber = (num: number): string => (
    new Intl.NumberFormat(locale.value).format(num)
  );
  const formatDateTime = (date: Date | string | number): string => {
    const d: Date = (typeof date === 'string' || typeof date === 'number')
      ? new Date(date)
      : date;
    return `${d.toLocaleDateString(locale.value)} ${d.toLocaleTimeString(locale.value)}`;
  };

  return {
    formatNumber,
    formatDateTime,
  };
}
