/**
 * Included in `@/src/main.ts`
 */

// @ts-ignore
import sentry from '@/plugins/sentry';
import i18n from '@/plugins/i18n';
import pinia from '@/stores';
import router from '@/router';
import type { App } from 'vue';

export default function registerPlugins(app: App) {
  app
    .use(sentry)
    .use(i18n)
    .use(pinia)
    .use(router);
}
