// external
import { ref } from 'vue';
import type { Ref } from 'vue';
// internal
import useModal from '@/composables/useModal';

export interface UseDropdow {
  dropdownMenuVisible: Ref<boolean>,
  closeDropdownMenu: () => void,
  toggleDropdownMenu: () => void,
  onDropdownMenuClickOutside: () => void,
}

export default function useDropdow(): UseDropdow {
  const {
    modalVisible: dropdownMenuVisible,
    openModal: openDropdownMenu,
    closeModal: closeDropdownMenu,
  } = useModal();

  function toggleDropdownMenu() {
    const now = Date.now();

    // if clicked on menu icon when dropdown menu is already open
    // don't reopen it
    if (now - drpdwnClickedOutside.value < 200) {
      drpdwnClickedOutside.value = 0;
      return;
    }

    if (dropdownMenuVisible.value) {
      closeDropdownMenu();
    } else {
      openDropdownMenu();
    }
  }
  const drpdwnClickedOutside = ref<number>(0);
  function onDropdownMenuClickOutside() {
    drpdwnClickedOutside.value = Date.now();
    closeDropdownMenu();
  }

  return {
    dropdownMenuVisible,
    closeDropdownMenu,
    toggleDropdownMenu,
    onDropdownMenuClickOutside,
  };
}
