// external
import type { AxiosInstance, AxiosResponse } from 'axios';
// internal
import type {
  Quest,
  QuestCheckResult,
} from '@/types';

export default (api: AxiosInstance) => ({
  getQuests(): Promise<AxiosResponse<Quest[]>> {
    return api.get<Quest[]>('/v1/tasks/');
  },
  checkQuest(qId: string): Promise<AxiosResponse<QuestCheckResult>> {
    return api.get<QuestCheckResult>(`/v1/tasks/check-task/${qId}/`);
  },
});
